import { useDispatch, useSelector } from "react-redux";

import { HideDialog } from "../store/modal/modal.actions";

import { modalState } from "../store/modal/modal.selectors";
import { navigate } from "@reach/router";

const success = require("./../images/success.svg");
const cross = require("./../images/icon/cross.svg");


const Dialog = ({ action }: { action ?:string}) => {

  const dispatch = useDispatch();

  const { text, point } = useSelector(
    modalState.getAllState
  );

  const textPoint = point <= 1 ? 'point' : 'points';
  return (
    <div className="domain-dialog" onClick={() => dispatch(HideDialog(false))}>
      <div
        className="domain-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="domain-dialog__head">
          <img className="domain-dialog__successIcon" src={success} alt="success" />
          <button
            className="domain-dialog__headIcon"
            onClick={() => dispatch(HideDialog(false))}
          >
            <img src={cross} alt="cross-icon" />
          </button>
          <p style={{ padding: "20px" }}>You have {action == 'edit'?'updated':'created'} a {text} with a total score of {point} {textPoint}.</p>
        </div>
        <div className="domain-dialog__footer">
          <button
            className="domain-dialog__footer__btn"
            onClick={() => {
              dispatch(HideDialog(false))
              if(localStorage.getItem("prevRoute")==="/recruitment/position-workflow/create")
              { 
                localStorage.setItem("prevRoute","")
                navigate("/recruitment/position-workflow/create");
              }else{
                navigate("/assessment/domain-assessment")
              }}
            }
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dialog;
