import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";

import MultySelect from "./MultySelect";
import { SetTimeDuration, SetStep, SetPassingScore, ClearQuestion, SetEditAction } from "../store/domain/domain.actions";
import { ClearData } from "../store/existingDomain/existingDomain.actions";
import { domainSelectors } from "../store/domain/domain.selectors";
import { existingDomainState } from "../store/existingDomain/existingDomain.selectors";
import { ShowDialog, SetText, SetPoint } from "../store/modal/modal.actions";
import { navigate } from "@reach/router";
import { getEnv } from "@urecruits/api";
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()

import { MultySelectStyle } from "../styles/MultySelectStyle";
import {
    timeDurationList,
} from "../api/optionsSelect";

import InputRange from "../components/_elements/InputRange";

import postData from "../hook/postData";
import putData from "../hook/putData";

const stepOne = require("./../images/stepOne.svg");
const stepTwo = require("./../images/circleEmpty.svg");
const cross = require("./../images/icon/cross.svg");
const upIcon = require("./../images/dropDownSelected.svg");
const line = require("./../images/line.svg");
const downIcon = require("./../images/dropDownIconCommon.svg");
const help = require("./../images/help.svg");


const StepThree = () => {

    const dispatch = useDispatch();
    const [data1, setData] = useState();
    const { industry, name, duration, passing, department, questions, editAction } = useSelector(
        domainSelectors.getAllState
    );
    const { addData, allScore } = useSelector(
        existingDomainState.getAllState
    );
    const unlock = duration && passing ? '' : 'disable';

    useEffect(() => {
        if (passing[0] > allScore) {
            dispatch(SetPassingScore([allScore]))
        }
    }, [])



    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={props.selectProps.menuIsOpen ? upIcon : downIcon} alt="up" />
                </components.DropdownIndicator>
            )
        );
    };

    const handleChangeDeadline = (selected) => {
        dispatch(SetTimeDuration(selected.label))
    }

    const backStep = () => {
        dispatch(SetStep(2))
    }
    const handleCancel = () => {
        dispatch(ClearData(0));
        dispatch(ClearQuestion(''));
    }

    const sendData = () => {

        let filterAnswer;

        if (addData && addData.length) {
            const allAddDataQuestion = addData.map(item => {
                return (
                    (item.questions)
                )
            })

            let test = []
            const allQuestion: any = allAddDataQuestion.map(item => {
                item?.map((data) => {
                    test?.push(data)
                })
            })

            const all = questions?.map((item) => {
                test?.push(item)
            })
            filterAnswer = test?.map(item => {
                const stringAnswer = item.type !== 'text' ? JSON.stringify(item.answers) : item.answers;
                return {
                    answers: stringAnswer,
                    name: item.name,
                    score: item.score,
                    type: item.type,
                }
            });
        } else {
            filterAnswer = questions.map(item => {
                const stringAnswer = item.type !== 'text' ? JSON.stringify(item.answers) : item.answers;
                return {
                    answers: stringAnswer,
                    name: item.name,
                    score: item.score,
                    type: item.type,
                }
            });
        }

        const data = {
            name,
            duration,
            passing: passing[0],
            industryId: industry,
            departmentId: department,
            questions: filterAnswer
        }
        editAction?.action === "edit" ? putData(`${API_ASSESSMENT}/api/domain-questions/${editAction?.id}`, data).then(
            (res) => {
                dispatch(SetText(name));
                dispatch(SetPoint(passing[0]));
                dispatch(ShowDialog(true));
                dispatch(ClearData(0));
                dispatch(ClearQuestion(''));
                dispatch(SetStep(1))
                dispatch(SetEditAction({ id: null, action: "" }))
                // return setData(res);
            },
            (err) => {
                console.error(err);
            }
        ) :
            postData(`${API_ASSESSMENT}/api/domain-questions`, data).then(
                (res) => {
                    postData(`${API_RECRUITMENT}/api/subscription/add-ons`, { addonName: 'assessment', type: 'increase' }).then()
                    dispatch(SetText(name));
                    dispatch(SetPoint(passing[0]));
                    dispatch(ShowDialog(true));
                    dispatch(ClearData(0));
                    dispatch(ClearQuestion(''));
                    dispatch(SetStep(1))
                    return setData(res);
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    return (
        <div className="domain-modal">
            <div
                className="domain-modal__container"
                onClick={(e) => e.stopPropagation()}
            >
                <div className='domain-modal__head__wrapper'>
                    <div className="domain-modal__head">
                        <h1 className="domain-modal__head__title">
                            {editAction?.action === "edit" ? "Edit" : "Add New"} Domain Assessment
                        </h1>
                        <button
                            className="domain-modal__head__cross-ico"
                            onClick={() => {
                                handleCancel()
                                navigate("/assessment/domain-assessment")
                            }}
                        >
                            <img src={cross} alt="cross-icon" />
                        </button>
                    </div>
                    <div className='domain-modal__circle__wrapper'>
                        <img className='domain-modal__circle' src={stepOne} alt="circle" />
                        <img className='domain-modal__line' src={line} alt="line" />
                        <img className='domain-modal__circle' src={stepTwo} alt="circle" />
                        <img src={line} alt="line" />
                        <img className='domain-modal__circle' src={stepTwo} alt="circle" />
                    </div>
                    <div className='domain-modal__subtitle__wrapper'>
                        <span className='domain-modal__subtitle'>
                            Assessment info
                        </span>
                        <span className='domain-modal__subtitle mg-left'>
                            Questions
                        </span>
                        <span className='domain-modal__subtitle'>
                            Time & Score
                        </span>
                    </div>
                </div>
                <div className="domain-modal__container__wrapper">
                    <div className="domain-modal__select mgTop">
                        <span className='domain-modal__label'>Time Duration <span className="customSelect__required"> *</span>

                            <div className="customSelect__action">
                                <div className="customSelect__action__item show">
                                    <img src={help} alt="eye icon" className="customSelect__actionIcon" />
                                    <div className="customSelect__action__info">
                                        <p className="customSelect__tooltipText">{"By what day a candidate has to complete the assessment"}</p>
                                    </div>
                                </div>
                            </div></span>
                        <MultySelect
                            openMenuOnClick={true}
                            options={timeDurationList}
                            menuPlacement='bottom'
                            placeholder='Choose the time duration'
                            hideSelectedOptions={false}
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            onChange={handleChangeDeadline}
                            value={timeDurationList.find(item => item.label === duration) as object}
                            styles={MultySelectStyle}
                        />
                    </div>
                    <div className="domain-modal__select mgBottom">
                        <h3 className="domain-modal__range__text">Passing Score<span className="domain-modal__required">*</span></h3>
                        <InputRange
                            values={passing[0] > allScore ? [allScore] : passing}
                            MAX={allScore}
                        />
                    </div>
                </div>

                <div className="domain-modal__footer js-sb">
                    <div>
                        <button
                            className="domain-modal__footer__back"
                            onClick={() => backStep()}
                        >
                            Back
                        </button>
                    </div>
                    <div
                        className="domain-modal__footer__container"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            className="domain-modal__footer__container__cancel"
                            onClick={() => {
                                if (localStorage.getItem("prevRoute") === "/recruitment/position-workflow/create") {
                                    localStorage.setItem("prevRoute", "")
                                    handleCancel()
                                    navigate("/recruitment/position-workflow/create");
                                } else {
                                    handleCancel()
                                    navigate("/assessment/domain-assessment");
                                }
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={unlock === 'disable' ? true : false}
                            className={`domain-modal__footer__container__save ${unlock}`}
                            onClick={() => sendData()}
                        >
                            {editAction?.action === "edit" ? "Save" : "Create"}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default StepThree;
