import {
  RouteComponentProps,
  LocationProvider,
  createHistory
} from "@reach/router";
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import "../styles/main.scss";
import Home from "../screen/Home";
import { Provider } from "react-redux";
import store from "../config/store";
import { permissionRoutesType, useHasPermission, UnAuthorizePageComponent, NotFoundPageComponent, useSubscription, UpgradeSubscriptionComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const routes:Array<permissionRoutesType>=[
  {
    path:'manage-domain/:action/:id',
    element:<Home />,
    module:'assessment',
    permissions:'edit',
    planPackages:['assessment','fullcycle']
  },
  {
    path:'manage-domain',
    element:<Home />,
    module:'assessment',
    permissions:'add',
    planPackages: ['assessment', 'fullcycle']
  }
]
export default function Root(props) {
  const history = createHistory(window);
  const { onAuthorizedRoutes, companyId } = useHasPermission()
  const { isLoadingSubscription, hasCompanySubscriptionElement, checkCurrentPackageType } = companyId && useSubscription();

  return (
    <>
      <LocationProvider history={history}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              {companyId && routes.map(route => {
                const isAuthorized = route.planPackages
                  ? checkCurrentPackageType(route.planPackages) &&
                  onAuthorizedRoutes(route.module, route.permissions, route?.option || "")
                  : onAuthorizedRoutes(route.module, route.permissions, route?.option || "");

                const routeElement = isAuthorized ? route.element : route.planPackages ? <UpgradeSubscriptionComponent/> : <UnAuthorizePageComponent />;

                return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(routeElement)} />;
              })}
              <Route path="/manage-domain/*" element={companyId && isLoadingSubscription ? <h1>Loading...</h1> :<NotFoundPageComponent/>}/>
            </Routes>
         </BrowserRouter>
        </Provider>
      </LocationProvider>
    </>
  );
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
